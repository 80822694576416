@use 'sass:map';

@mixin xmqtt-device-overview-theme($theme) {
  $text-white: map.get($theme, al-color-text-white);
  .xmqtt-device-overview {
    span.iot-platform-ui-status-button__text {
      text-transform: inherit !important;
    }
  }
}
