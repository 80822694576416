@use 'sass:map';

@mixin xmqtt-device-information-tab-theme($theme) {
  $text-secondary: map.get($theme, al-color-text-secondary);
  $grey100: map.get($theme, al-color-palette-grey100);

  .loading .iot-platform-ui-tab-group__container {
    align-self: center;
  }
}
